#navbar {
  z-index: 3;
  background-color: #0000;
  width: 100vw;
  margin-top: 2vh;
  padding-top: 2vh;
  transition: transform .5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.nav-content {
  justify-content: space-between;
  align-items: center;
  margin: 0 9vw;
  display: flex;
}

.left-section {
  align-items: center;
  display: flex;
}

.logo img {
  height: 42px;
}

.logo-img {
  content: url("white_colored_horizontal.19f52191.webp");
}

.right-section {
  align-items: center;
  gap: 12px;
  display: flex;
}

.right-section > * {
  align-items: center;
  display: flex;
}

.right-section > :last-child {
  border-left: 2px solid #fff;
  margin-left: 10px;
}

.follow-us {
  color: #fff;
}

.social-button {
  aspect-ratio: 1;
  pointer-events: auto;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 65px;
  display: flex;
  overflow: hidden;
}

.social-button.discord {
  backdrop-filter: blur(9px);
  background: #5865f2;
  border-left: 0 solid #fff;
}

.social-button.instagram {
  background: radial-gradient(61.46% 59.09% at 36.25% 96.55%, #ffd600 0%, #ff6930 48.44%, #fe3b36 73.44%, #fe3b3600 100%), radial-gradient(202.83% 136.37% at 84.5% 113.5%, #ff1b90 24.39%, #f80261 43.67%, #ed00c0 68.85%, #c500e9 77.68%, #7017ff 89.32%);
  border-left: 0 solid #fff;
}

.social-button.linkedin {
  background: #0a66c2;
  border-left: 0 solid #fff;
}

.social-button-navbar {
  aspect-ratio: 1;
  pointer-events: auto;
  border-left: 0 solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 42px;
  display: flex;
  overflow: hidden;
}

.social-button-navbar.discord {
  backdrop-filter: blur(9px);
  background: #5865f2;
}

.social-button-navbar.instagram {
  background: radial-gradient(61.46% 59.09% at 36.25% 96.55%, #ffd600 0%, #ff6930 48.44%, #fe3b36 73.44%, #fe3b3600 100%), radial-gradient(202.83% 136.37% at 84.5% 113.5%, #ff1b90 24.39%, #f80261 43.67%, #ed00c0 68.85%, #c500e9 77.68%, #7017ff 89.32%);
}

.social-button-navbar.linkedin {
  background: #0a66c2;
}

.menu-container {
  height: 37px;
  position: relative;
}

.menu-toggle {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 6vh;
  height: 6vh;
  display: flex;
}

.menu-toggle .hamburger, .menu-toggle .hamburger:before, .menu-toggle .hamburger:after {
  background-color: #fff;
  width: 60%;
  height: 2px;
  display: block;
  position: relative;
}

.menu-toggle .hamburger:before, .menu-toggle .hamburger:after {
  content: "";
  position: absolute;
  left: 0;
}

.menu-toggle .hamburger:before {
  top: -6px;
}

.menu-toggle .hamburger:after {
  top: 6px;
}

.nav-links {
  backdrop-filter: blur(10px);
  z-index: 4;
  background: #80808080;
  border-radius: 10px;
  flex-direction: column;
  min-width: 150px;
  padding: 10px 20px;
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
}

.nav-links.active {
  display: flex;
}

.nav-links li {
  margin: 10px 0;
}

.nav-links a {
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

.desktop-only {
  display: flex;
}

.mobile-only {
  display: none;
}

@media (width <= 750px) {
  .logo-img {
    content: url("white_logo_only.e03d3f29.webp");
  }

  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    align-items: center;
    gap: 10px;
    display: flex !important;
  }

  .right-section > :not(:first-child) {
    border-left: none;
    margin-left: 0;
  }
}
/*# sourceMappingURL=index.d03117d9.css.map */
