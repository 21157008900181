/* Base Navbar */
#navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding-top: 2vh;
  margin-top: 2vh;
  background-color: transparent;
  z-index: 3;
  transition: transform 0.5s ease-in-out;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 9vw;
}
.left-section {
  display: flex;
  align-items: center;
}

.logo img {
  height: 42px;
}
/* Desktop logo by default */
.logo-img {
  content: url("../assets/img/white_colored_horizontal.webp");
}

/* Right section layout */
.right-section {
  display: flex;
  align-items: center;
  gap: 12px;
}
.right-section > * {
  display: flex;
  align-items: center;
}
.right-section > *:is(:last-child) {
  border-left: 2px solid #ffffff;
  margin-left: 10px;
}

/* Follow us text color */
.follow-us {
  color: #ffffff;
}

/* Social Buttons */
.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  overflow: hidden; /* Ensure it remains a perfect circle */
  pointer-events: auto;
}


/* Social Button Backgrounds */
.social-button.discord {
  background: #5865F2;
  backdrop-filter: blur(9px);
  border-left: 0px solid #ffffff;
}

.social-button.instagram {
  background: radial-gradient(
      61.46% 59.09% at 36.25% 96.55%,
      #ffd600 0%,
      #ff6930 48.44%,
      #fe3b36 73.44%,
      rgba(254, 59, 54, 0) 100%
    ),
    radial-gradient(
      202.83% 136.37% at 84.5% 113.5%,
      #ff1b90 24.39%,
      #f80261 43.67%,
      #ed00c0 68.85%,
      #c500e9 77.68%,
      #7017ff 89.32%
    );
  border-left: 0px solid #ffffff;

}
.social-button.linkedin {
  background: #0a66c2;
  border-left: 0px solid #ffffff;

}
/* Social icons styling */
.social-button-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  pointer-events: auto;
  border-left: 0px solid #ffffff;
}

.social-button-navbar.discord {
  background: #5865F2;
  backdrop-filter: blur(9px);
}
.social-button-navbar.instagram {
  background: radial-gradient(
      61.46% 59.09% at 36.25% 96.55%,
      #ffd600 0%,
      #ff6930 48.44%,
      #fe3b36 73.44%,
      rgba(254, 59, 54, 0) 100%
    ),
    radial-gradient(
      202.83% 136.37% at 84.5% 113.5%,
      #ff1b90 24.39%,
      #f80261 43.67%,
      #ed00c0 68.85%,
      #c500e9 77.68%,
      #7017ff 89.32%
    );
}
.social-button-navbar.linkedin {
  background: #0a66c2;
}

/* Hamburger Menu */
.menu-container {
  position: relative;
  height: 37px;
}

.menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
  width: 6vh;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-toggle .hamburger,
.menu-toggle .hamburger::before,
.menu-toggle .hamburger::after {
  width: 60%;
  height: 2px;
  background-color: #ffffff;
  display: block;
  position: relative;
}

.menu-toggle .hamburger::before,
.menu-toggle .hamburger::after {
  content: '';
  position: absolute;
  left: 0;
}

.menu-toggle .hamburger::before {
  top: -6px;
}
.menu-toggle .hamburger::after {
  top: 6px;
}

/* Dropdown Menu */
.nav-links {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background: rgba(128, 128, 128, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  flex-direction: column;
  padding: 10px 20px;
  z-index: 4;
  min-width: 150px;
}

.nav-links.active {
  display: flex;
}

.nav-links li {
  margin: 10px 0;
}

.nav-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1em;
  cursor: pointer;
}
.nav-links a:hover {
  text-decoration: underline;
}

/* Desktop-only class: shown on wider screens */
.desktop-only {
  display: flex;
}

/* Mobile-only class: hidden on wider screens by default */
.mobile-only {
  display: none;
}

/* Responsive Adjustments */
@media (max-width: 750px) {
  /* Change logo to a smaller version */
  .logo-img {
    content: url("../assets/img/white_logo_only.webp");
  }

  /* Hide desktop follow-us and social icons on mobile */
  .desktop-only {
    display: none !important;
  }

  /* Show mobile-only inside nav menu on mobile */
  .mobile-only {
    display: flex !important;
    gap: 10px;
    align-items: center;
  }

  /* On mobile, you can also remove the border-left on right-section if desired */
  .right-section > *:not(:first-child) {
    border-left: none;
    margin-left: 0;
  }
}
